<template>
  <div class="innovate-box">
    <!--  引入头部-->
    <HeadMobile carouseType="INNOVATE"></HeadMobile>
    <div class="innovate-body">
      <div class="innovate-body-title">
        <div class="title">{{ $store.state.staticName.innovation }}</div>
        <div class="xian"></div>
      </div>
      <div class="innovate-detail-list">
        <div class="detail-item"
             v-for="(item, index) in innovateList"
             :key="'inn'+index"
             :style="index%2===1?'background: #fafafa;':''">
          <!--          左边文字描述-->
          <div class="text-box-left" v-if="index%2===1">
            <div class="detail-title">
              {{ item.title }}
            </div>
            <div class="detail-text">
              {{ item.text }}
            </div>
          </div>
          <div class="img-box" :style="index%2===1?'':'margin-right: 9px'">
              <img :src="item.abbreviation[0]"/>
          </div>
          <!--          右边文字描述-->
          <div class="text-box-right" v-if="index%2!==1">
            <div class="detail-title">
              {{ item.title }}
            </div>
            <div class="detail-text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  引入脚部-->
    <FootMobile></FootMobile>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";
import { mapState } from 'vuex';
export default {
  name: "innovate",
  components: {
    HeadMobile,
    FootMobile
  },
  data(){
    return {
      // 技术创新数组
      innovateList: []
    }
  },
  created() {
    // 查询数据
    this.queryInnovate()
  },
  watch:{
    "$store.state.languageType": {
      handler(newVal) {
        this.queryInnovate()
      },
      // deep: true // 深度监听
    }
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    })
  },
  methods:{
    queryInnovate(){
      this.$axios({
        url:'/queryPRO_SkillAll',
        method:'GET',
        params:{
          type: this.languageType
        }
      }).then(res=>{
        this.innovateList = res.data.value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .innovate-box{
    background: #FFFFFF;
    .innovate-body{
      .innovate-body-title{
        margin: 14px auto 0;
        width: 375px;
        height: 50px;
        .title{
          margin: 0 auto;
          white-space:nowrap;
          text-overflow:ellipsis;
          width: 353px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #4d4d4d;
        }
        .xian{
          width: 353px;
          margin: 8px auto 0;
          border-bottom: 1px solid #808080;
        }
      }
      .innovate-detail-list{
        min-height: 127px;
        .detail-item{
          width: 375px;
          height: 127px;
          background: #e6e6e6;
          display: flex;
          margin-bottom: 20px;
          .img-box{
            //margin-left: 11px;
            width: 171px;
            height: 127px;
            background: rgba(0,0,0,0.1);
            img{
              width: 171px;
              height: 127px;
            }
          }
          .text-box-right{
            width: 177px;
            height: 127px;

          }
          .text-box-left{
            margin-left: 9px;
            width: 177px;
            height: 127px;

          }
          .detail-title{
            margin-top: 6px;
            width: 177px;
            height: 20px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #4d4d4d;
          }
          .detail-text{
            margin-top: 3px;
            width: 177px;
            height: 96px;
            text-indent:2em;
            word-break: break-all;
            display: -webkit-box; /**对象作为伸缩盒子模型展示**/
            -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
            -webkit-line-clamp: 8; /**显示的行数**/
            overflow: hidden; /**隐藏超出的内容**/
            font-size: 11px;
            font-weight: 400;
            text-align: left;
            color: #666666;
            margin-right: 11px;
          }
        }
      }
    }
  }
</style>