<template>
  <div class="head">
    <div class="head-div">
      <img src="@/assets/logo.jpeg" class="head-logo" />
      <div class="head-share">
        <!--        <span class="iconfont iconlujing10"></span>
        <span class="iconfont iconlujing8"></span>
        <span class="iconfont iconlujing9 fontsize"></span>-->
        <el-select
          class="select-b"
          v-model="language"
          @change="languageSwitch(language)"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="head-list">
      <div
        v-for="(item, i) in menu"
        :key="'xx' + i"
        @click="toPage(item.path)"
        class="head-list-li"
        :class="
          $route.path.indexOf(item.path) !== -1 ||
          ($route.path.indexOf('/TitaniumProductsMobile') !== -1 && i === 1)
            ? 'active'
            : ''
        "
      >
        <p>{{ item.name }}</p>
      </div>
      <!--      <div @click="toPage('')" class="head-list-li">-->
      <!--        <p>{{$store.state.staticName.customized}}</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('/ProductMobile')" class="head-list-li">-->
      <!--        <p>{{$store.state.staticName.product}}</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('')" class="head-list-li">-->
      <!--        <p>{{$store.state.staticName.quality}}</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('')" class="head-list-li">-->
      <!--        <p>{{$store.state.staticName.innovation}}</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('/GoWuYueMobile')" class="head-list-li">-->
      <!--        <p>{{$store.state.staticName.AboutUs}}</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('/ContactUsMobile')" class="head-list-li">-->
      <!--        <p>{{$store.state.staticName.ContactUs}}</p>-->
      <!--      </div>-->
      <div class="head-list-li">
        <p></p>
      </div>
    </div>
    <el-carousel v-if="carouseShow">
      <el-carousel-item v-for="(item, i) in carouselList" :key="'wq' + i">
        <img style="width: 100%; height: 100%" :src="item.advert" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import lang from "@/assets/json/lang";

export default {
  name: "HeadMobile",
  props: {
    carouseShow: {
      type: Boolean,
      default: true,
    },
    carouseType: {
      type: String,
      default: "HOME",
    },
  },
  data() {
    return {
      menu: [],
      carouselList: [],
      options: [
        {
          value: "CHINESE",
          label: "中文",
        },
        {
          value: "ENGLISH",
          label: "english",
        },
      ],
      // 语言
      language: "",
    };
  },
  created() {
    this.language = this.$store.state.languageType;

    this.getStaticData();
    this.menu = [
      { id: 1, path: "/HomeMobile", name: this.$store.state.staticName.home },
      {
        id: 2,
        path: "/CorecustomizaMobile",
        name: this.$store.state.staticName.customized,
      },
      {
        id: 3,
        path: "/ProductMobile",
        name: this.$store.state.staticName.product,
      },
      {
        id: 4,
        path: "/qualityMobile",
        name: this.$store.state.staticName.quality,
      },
      {
        id: 5,
        path: "/innovateMobile",
        name: this.$store.state.staticName.innovation,
      },
      {
        id: 6,
        path: "/GoWuYueMobile",
        name: this.$store.state.staticName.AboutUs,
      },
      {
        id: 7,
        path: "/ContactUsMobile",
        name: this.$store.state.staticName.ContactUs,
      },
    ];
    this.getAdData();
  },
  computed: {
    ...mapGetters([
      // 'getAdList',
      "staticName",
    ]),
  },
  methods: {
    getAdData() {
      this.$axios({
        url: "/queryAdvertsByAll",
        method: "get",
        params: {
          type: this.carouseType,
        },
      }).then((res) => {
        this.carouselList = res.data.value;
        // this.$store.dispatch('setAdList', res.data.value);
      });
    },
    getStaticData() {
      if (this.$store.state.languageType === "CHINESE") {
        this.$store.dispatch("setStaticName", lang.Chinese);
      } else {
        this.$store.dispatch("setStaticName", lang.English);
      }
      // console.log(this.staticName)
    },
    languageSwitch(data) {
      this.$store.dispatch("setLanguageType", data);
      this.getStaticData();
      this.menu = [
        { id: 1, path: "/HomeMobile", name: this.$store.state.staticName.home },
        {
          id: 2,
          path: "/CorecustomizaMobile",
          name: this.$store.state.staticName.customized,
        },
        {
          id: 3,
          path: "/ProductMobile",
          name: this.$store.state.staticName.product,
        },
        {
          id: 4,
          path: "/qualityMobile",
          name: this.$store.state.staticName.quality,
        },
        {
          id: 5,
          path: "/innovateMobile",
          name: this.$store.state.staticName.innovation,
        },
        {
          id: 6,
          path: "/GoWuYueMobile",
          name: this.$store.state.staticName.AboutUs,
        },
        {
          id: 7,
          path: "/ContactUsMobile",
          name: this.$store.state.staticName.ContactUs,
        },
      ];
      // console.log(data)
    },
    toPage(url) {
      // console.log(url)
      if (url === "") {
        this.$message("暂未开放");
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.fontsize:before {
  font-size: 23px !important;
}
.active {
  background-color: white !important;

  p {
    color: #1850af !important;
  }
}
:deep(.el-carousel__container),
:deep(.el-carousel--horizontal) {
  max-width: 374px;
  height: 180px;
}
:deep(.el-carousel__indicators) {
  //background-color: #999999;
}
:deep(.el-carousel__button) {
  width: 7px;
  height: 7px;
  border-radius: 15px;
  margin-right: 15px;
  background: #ffffff;
  opacity: 1;
}
:deep(.el-carousel__indicator.is-active button) {
  background: #1850af;
}
.head {
  display: flex;
  flex-direction: column;

  .head-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    .head-logo {
      width: 71px;
      height: 36px;
      margin-left: 18px;
    }

    .head-share {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 15px;
      .select-b {
        width: 76px;
      }
      span::before {
        font-size: 19px;
        color: #1850af;
        margin-right: 14px;
      }
    }
  }

  .head-list {
    display: grid;
    //width: calc(100% - 130px - 130px);
    //height: 68px;
    background-color: #1850af;
    grid-template-columns: 95px 95px 95px 95px;
    grid-template-rows: 35px 35px;
    //grid-row-gap: 38px;
    //flex-direction: row;
    //flex-wrap: wrap;
    //padding: {
    //  left: 130px;
    //  right: 130px;
    //};
    font-size: 12px;
    .head-list-li {
      //flex-grow: 1;
      text-align: center;
      width: 93px;
      height: 34px;
      border: 1px solid #ffffff;
      margin-left: -1px;
      margin-top: -1px;
      p {
        margin: 0;
        //font-size: 24px;
        color: white;
        line-height: 34px;
      }
    }

    .head-list-li:hover {
      background-color: white;

      p {
        color: #1850af;
      }
    }
  }
}
</style>
