export default {
  Chinese: {
    home: "首页",
    customized: "定制化服务",
    product: "产品中心",
    quality: "质量保证",
    innovation: "技术创新",
    AboutUs: "走进午跃",
    ContactUs: "联系我们",
    Hotline: "服务热线",
    service_date: "服务时间",
    company_address: "公司地址：四川省攀枝花市西区格里坪经堂村马上街88号",
    address: "公司地址",
    postal_code: "邮政编码",
    email: "客服邮箱",
    home_title_1: "钛-定制化服务",
    home_subhead_1: "多元化定制，满足各种所需",
    home_title_2: "钛-产品中心",
    home_subhead_2: "提升的不仅是质量",
    home_about_us: "关于我们",
    home_industry_trends: "行业动态",
    media_report: "媒体报道",
    read_more: "查看更多",
    view_details: "查看详情",
    cus_title: "核心定制服务",
    cus_subhead: "多元化定制，满足各种所需",
    product_title: "产品中心",
    quality_title: "先进的生产设备",
    company_profiles: "企业介绍",
    glories: "公司荣誉",
    client: "合作客户",
    company_photo: "公司客户",
    video: "相册&视频",
    company: "攀枝花市午跃科技有限公司",
    classify: "产品类目",
    company_name: "公司名称",
    create_date: "成立时间",
    legal_person: "法定代表人",
    office_clerk: "职工人数",
    technical_support: "技术支持",
    business_scope: "经营范围",
    Company_certification: "公司认证",
    production_capacity: "生产能力",
    management_mode: "管理模式",
    domestic_client: "国内客户",
    overseas_customers: "海外客户",
    phone: "电话",
    Back_to_Top: "返回顶部",
    WeChat_Official_Account: "微信公众号",
    WeChat_app: "微信小程序",
    position: "位置",
    Last: "上一篇",
    Next: "下一篇",
    Customizationoia: "工业配件定制",
    Customizationoctp: "民用钛制品定制",
    Customizedspofctp: "民用钛制品定制服务流程",
    Clickscan: "扫码购买",
    ContactHotline: "联系热线",
    Contactservice: "联系客服",
    // Productintroduction: "产品介绍",
    Productintroduction: "规格介绍",
    Customizedcustomers: "定制客户",
    Usepurpose: "使用用途",
    Productsize: "产品尺寸",
    Productmaterial: "产品材质",
    Productprocess: "产品工艺",
    zhou: "周一至周五",
    xiu: "节假日休息",
    production_equipment: "生产设备",
    team: "我们的团队",
  },
  English: {
    home: "Home",
    customized: "Customized",
    product: "Product",
    quality: "Quality",
    innovation: "Innovation",
    AboutUs: "About Us",
    ContactUs: "Contact Us",
    Hotline: "Hotline",
    service_date: "service date",
    company_address:
      "address: No. 88, Ma Shang Street, Jingtang Village, Geliping, West District, Panzhihua, Sichuan Province",
    address: "address",
    postal_code: "postal code",
    email: "email",
    home_title_1: "Titanium - customization service",
    home_subhead_1: "Diversified customization to meet various needs",
    home_title_2: "Titanium - Product center",
    home_subhead_2: "It's not just about quality",
    home_about_us: "About us",
    home_industry_trends: "industry trends",
    media_report: "media report",
    read_more: "read more",
    view_details: "view details",
    cus_title: "Core customization services",
    cus_subhead: "Diversified customization to meet various needs",
    pro_product: "Product",
    quality_title: "advanced production equipment ",
    production_equipment: "Production Equipment",
    team: "Ours Team",
    company_profiles: "introduction",
    glories: "honors",
    client: "cooperative client",
    company_photo: "clientele",
    video: "video&photo",
    WeChat_Account: "WeChat Official Account",
    WeChat_app: "mall",
    Back_to_Top: "Back to Top",
    company: "Panzhihua Wuyue Technology Co. LTD",
    classify: "category",
    company_name: "company name",
    create_date: "date of establishment",
    legal_person: "legal person",
    office_clerk: "office clerk",
    technical_support: "technical support",
    business_scope: "business scope",
    Company_certification: "Company certification",
    production_capacity: "production capacity",
    management_mode: "management mode",
    domestic_client: "domestic client",
    overseas_customers: "overseas customers",
    phone: "phone",
    WeChat_Official_Account: "WeChat Official Account",
    position: "position",
    Last: "Last",
    Next: "Next",
    Customizationoia: "Customization of industrial accessories",
    Customizationoctp: "Customization of civil titanium products",
    Customizedspofctp: "Customized service process of civil titanium products",
    Clickscan: "buy",
    ContactHotline: "Contact Hotline",
    Contactservice: "Contact customer service",
    // Productintroduction: "Product introduction",
    Productintroduction: "Specifications",
    Customizedcustomers: "Customized customers",
    Usepurpose: "Use purpose",
    Productsize: "Product size",
    Productmaterial: "Product material",
    Productprocess: "Product process",
    zhou: "Monday to Friday",
    xiu: "Holiday break",
  },
};
