<template>
  <!--脚部外部壳子-->
  <div class="foot">
    <div class="float-window">
      <div class="float-icon-box" v-show="isOpen">
        <!--        <span class="iconfont iconlujing59" @click="handleCopy('123123123')"></span>-->
        <span
          class="iconfont iconzu193"
          @click="toPage('/ContactUsMobile')"
        ></span>
        <span
          class="iconfont iconlujing58"
          @click="handlePhone('400-689-8628')"
        ></span>
        <span
          class="iconfont iconlujing63"
          @click="toPage('/HomeMobile')"
        ></span>
      </div>
      <div class="open" @click="handleOpen">
        <i
          :class="
            isOpen
              ? 'iconfont icongengduo-left-copy'
              : 'iconfont icongengduo-right-copy'
          "
        ></i>
      </div>
    </div>
    <div class="top-box">
      <div class="top" @click="goToTop">
        <div class="icon">
          <i class="iconfont iconzu197"></i>
        </div>
        <div>{{ $store.state.staticName.Back_to_Top }}</div>
      </div>
    </div>
    <div class="navbar-box">
      <div @click="toPage('/HomeMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.home }}</p>
      </div>
      <div @click="toPage('/CorecustomizaMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.customized }}</p>
      </div>
      <div @click="toPage('/ProductMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.product }}</p>
      </div>
      <div @click="toPage('/qualityMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.quality }}</p>
      </div>
      <div @click="toPage('/innovateMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.innovation }}</p>
      </div>
      <div @click="toPage('/GoWuYueMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.AboutUs }}</p>
      </div>
      <div @click="toPage('/ContactUsMobile')" class="navbar-list-li">
        <p>{{ $store.state.staticName.ContactUs }}</p>
      </div>
    </div>
    <div class="about-us-box">
      <div class="about-us-left">
        <div class="title">
          <i class="iconfont iconlujing52"></i>&nbsp;&nbsp;<span>{{
            $store.state.staticName.Hotline
          }}</span>
        </div>
        <div class="phone text">400-689-8628</div>
        <div class="date text">
          {{ $store.state.staticName.service_date }}：{{
            $store.state.staticName.zhou
          }}
          9：00-18：00 {{ $store.state.staticName.xiu }}
        </div>
        <div class="qq text">QQ:930338998</div>
        <div class="email text">
          {{ $store.state.staticName.email }}：wuyuekeji201781@sina.com
        </div>
        <div class="address text">
          {{ $store.state.staticName.company_address }}
        </div>
      </div>
      <div class="about-us-right">
        <div class="code-box">
          <div class="img-box">
            <div class="img">
              <img class="img1" src="../../assets/gongzhonggao.jpg" alt="" />
            </div>
          </div>
          <div class="text">
            {{ $store.state.staticName.WeChat_Official_Account }}
          </div>
        </div>
        <div class="code-box">
          <div class="img-box">
            <div class="img">
              <img class="img1" src="../../assets/gongzhonggao.jpg" alt="" />
            </div>
          </div>
          <div class="text">{{ $store.state.staticName.WeChat_app }}</div>
        </div>
      </div>
    </div>

    <div class="xian"></div>
    <div class="foot-copy">
      <!-- >备案号：蜀ICP备20017739号-1</a -->
      <a href="http://beian.miit.gov.cn" target="_blank"
        >备案号：蜀ICP备2023008831号-1</a
      >
      <br />
      &nbsp;&nbsp;&nbsp;工信部查询网站：<a
        href="https://beian.miit.gov.cn"
        rel="noopener"
        target="_blank"
        style="
          outline: none;
          cursor: pointer;
          color: #00a2ca;
          line-height: 22px;
        "
        microsoft="background-color:#ffffff;"
        >www.b<wbr />eian.miit.go<wbr />v.cn</a
      >
      <p></p>
      Copyright © 2023 攀枝花市午跃科技有限公司 版权所有 <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "FootMobile",
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    goToTop() {
      let scrollTop = document.documentElement.scrollTop;
      let e = setInterval(function () {
        scrollTop -= 15;
        document.documentElement.scrollTop = scrollTop;
        if (scrollTop < 0) {
          // console.log(scrollTop)
          clearInterval(e);
        }
      }, 7);
    },
    handlePhone(text) {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      // 微信内
      if (isWeixin) {
        alert("请在浏览器上打开");
      } else {
        //android端
        if (isAndroid) {
          //安卓app的scheme协议
          window.location.href = "tel:" + this.phone;
          // setTimeout(function(){
          //   let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden
          //   if(typeof hidden =="undefined" || hidden ==false){
          //     //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
          //     window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient";
          //   }
          // }, 2000);
        }
        //ios端
        if (isIOS) {
          //ios的scheme协议
          window.location.href = "tel:" + this.phone;
          // setTimeout(function(){
          //   let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden
          //   if(typeof hidden =="undefined" || hidden ==false){
          //     //App store下载地址
          //     window.location.href = "http://itunes.apple.com/app/id387682726";
          //   }
          // }, 2000);
        }
      }
    },
    handleCopy(text) {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      // 微信内
      if (isWeixin) {
        alert("请在浏览器上打开");
      } else {
        //android端
        if (isAndroid) {
          //安卓app的scheme协议
          window.location.href =
            "http://wpa.qq.com/msgrd?v=3&uin=&site=qq&menu=yes";
          // setTimeout(function(){
          //   let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden
          //   if(typeof hidden =="undefined" || hidden ==false){
          //     //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
          //     window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient";
          //   }
          // }, 2000);
        }
        //ios端
        if (isIOS) {
          //ios的scheme协议
          window.location.href = "tel:" + this.phone;
          // setTimeout(function(){
          //   let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden
          //   if(typeof hidden =="undefined" || hidden ==false){
          //     //App store下载地址
          //     window.location.href = "http://itunes.apple.com/app/id387682726";
          //   }
          // }, 2000);
        }
      }

      // let _this = this
      // // 创建虚拟dom
      // const input = document.createElement("input")
      // document.body.appendChild(input)
      // // 赋值dom
      // input.setAttribute('value', text)
      // input.select()
      // if (document.execCommand("Copy", "false", null)) {
      //   _this.$message.success("内容已复制到剪切板")
      // } else {
      //   _this.$message.error("内容复制失败，请重新试试")
      // }
      // 销毁dom节点
      // document.body.removeChild(input)
    },
    handleOpen() {
      this.isOpen = !this.isOpen;
    },
    toPage(url) {
      if (url === "") {
        this.$message("暂未开放");
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.img1 {
  width: 100%;
  height: 100%;
}
.foot {
  background: #1850af;

  .float-window {
    position: fixed;
    bottom: 130px;
    right: 1px;
    z-index: 2;
    display: flex;

    .float-icon-box {
      width: 207px;
      height: 42px;
      background: #575757;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: 15px;

      .iconfont {
        flex: 1;
        font-size: 28px;
        font-weight: 300;
        color: #ffffff;
      }
    }

    .open {
      width: 24px;
      height: 42px;
      background: #575757;
      border-radius: 5px;
      font-size: 32px;
      font-weight: 300;
      margin-left: 3px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .top-box {
    width: 375px;
    height: 45px;
    background: #1850af;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #ffffff 2px solid;
    padding-bottom: 6px;

    .icon {
      margin-bottom: 2px;
      //width: 68px;
      text-align: center;
    }

    i {
      font-size: 15px;
    }

    i:before {
      color: #ffffff;
    }

    div {
      //width: 9px;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
    }
  }

  .navbar-box {
    display: grid;
    background-color: #1850af;
    grid-template-columns: 95px 95px 95px 95px;
    grid-template-rows: 35px 35px;
    font-size: 12px;

    .navbar-list-li {
      text-align: center;
      width: 93px;
      height: 34px;
      margin-left: -1px;
      margin-top: -1px;

      p {
        margin: 0;
        color: white;
        line-height: 34px;
      }
    }

    .head-list-li:hover {
      background-color: white;

      p {
        color: #1850af;
      }
    }
  }

  .about-us-box {
    margin-top: 9px;
    margin-left: 11px;
    display: flex;

    .about-us-left {
      .title {
        font-size: 10px;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        margin-bottom: 4px;

        i {
          font-size: 18px;
        }
      }

      .text {
        width: 240px;
        margin-top: 6px;
        font-size: 10px;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        word-break: break-all;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical;
      }

      .phone {
        font-size: 12px;
      }
    }

    .about-us-right {
      margin-top: -10px;
      margin-left: 20px;

      .code-box {
        margin-bottom: 5px;

        .img-box {
          width: 100px;
          height: 59px;
          text-align: center;
          margin-bottom: 2px;

          .img {
            margin: 0 auto;
            width: 58px;
            height: 59px;
            background: #ffffff;
          }
        }

        .text {
          width: 100px;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }

  .xian {
    width: 375px;
    height: 1px;
    border-bottom: 1px #ffffff solid;
  }
  .foot-copy {
    margin: 20px auto 0;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  .code {
    width: 375px;
    height: 15px;
    font-size: 8px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
}
</style>
